//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import BackgroundImage from "gatsby-background-image-es5";
import { FiPhoneCall } from "react-icons/fi";
import { motion } from "framer-motion";

function HomeHero(props) {
	return (
		<BackgroundImage
			fluid={props.image}
			className="relative overflow-hidden shadow-xl"
		>
			<div
				className="mx-auto relative"
				style={{
					backgroundSize: "cover",
					backgroundPosition: "top center",
					background:
						"linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0.2))",
				}}
			>
				<div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
					<main className="pt-10 mx-auto max-w-screen-xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
						<div className="sm:text-center lg:text-left">
							<motion.div
								style={{ originY: 1 }}
								initial="hidden"
								animate="visible"
								variants={{
									hidden: {
										scale: 0.8,
										opacity: 0,
									},
									visible: {
										scale: 1,
										opacity: 1,
										transition: {
											delay: 0.6,
										},
									},
								}}
							>
								<h2 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
									{props.headline}
								</h2>
							</motion.div>
							<motion.div
								style={{ originY: 1 }}
								initial="hidden"
								animate="visible"
								variants={{
									hidden: {
										scale: 0.8,
										opacity: 0,
									},
									visible: {
										scale: 1,
										opacity: 1,
										transition: {
											delay: 0.8,
										},
									},
								}}
							>
								<p className="mt-3 text-gray-200 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 font-semibold">
									{props.copy}
								</p>
							</motion.div>

							<div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
								<motion.div
									style={{ originY: 1 }}
									initial="hidden"
									animate="visible"
									variants={{
										hidden: {
											scale: 0.8,
											opacity: 0,
										},
										visible: {
											scale: 1,
											opacity: 1,
											transition: {
												delay: 1,
											},
										},
									}}
								>
									<div className="rounded-md shadow">
										<a
											href={"tel:" + props.phone}
											className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
										>
											<FiPhoneCall /> <span className="call"> Call Us!</span>
										</a>
									</div>
								</motion.div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</BackgroundImage>
	);
}

export default HomeHero;
